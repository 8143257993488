<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('graduation_simulation')"
                 :isFilter="false"/>
            </template>
            <template v-slot:header-mobile>
                <header-mobile :title="$t('graduation_simulation')"
                               :isFilter="false"/>
            </template>
            <div>
                <div class="border rounded-sm p-3 p-lg-5 mb-3 datatable-filter">
                    <div class="d-flex flex-column flex-xl-row">
                        <div class="flex-grow-1 mr-lg-4">
                            <ValidationObserver ref="formModalValidate">
                                <b-row>
                                    <b-col sm="3">
                                        <ValidationProvider name="student_numbers" :rules="formData.filter.student_numbers == '' ? 'required' : ''" v-slot="{errors}">
                                            <b-form-group :label="$t('student_numbers')">
                                                <lined-textarea
                                                    v-model="formData.filter.student_number"
                                                    :nowrap="false"
                                                    :disabled="false"
                                                    :styles="{ height: '184px' }"
                                                    :validate-error="errors[0]"
                                                />
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="9">
                                        <ValidationProvider name="faculty" :rules="formData.filter.student_number == '' ? 'required' : ''" v-slot="{errors}">
                                            <b-form-group :label="$t('faculty')">
                                                <faculty-selectbox
                                                    v-model="formData.filter.faculty_code"
                                                    :validate-error="errors[0]"
                                                />
                                            </b-form-group>
                                        </ValidationProvider>
                                        <ValidationProvider name="department_code" rules="" v-slot="{errors}">
                                            <b-form-group :label="$t('department')">
                                                <department-selectbox
                                                    v-model="formData.filter.department_code"
                                                    :faculty_code="formData.filter.faculty_code"
                                                    :validate-error="errors[0]"
                                                />
                                            </b-form-group>
                                        </ValidationProvider>
                                        <ValidationProvider name="program_code" rules="" v-slot="{errors}">
                                            <b-form-group :label="$t('program')">
                                                <program-selectbox
                                                    v-model="formData.filter.program_code"
                                                    :faculty_code="formData.filter.faculty_code"
                                                    :department_code="formData.filter.department_code"
                                                    :validate-error="errors[0]"
                                                />
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col sm="9">
                                        <processing-button @click="save" :label="'start_process'"/>
                                    </b-col>
                                </b-row>
                            </ValidationObserver>
                        </div>
                    </div>
                </div>
            </div>
<!--            <CommonModal ref="graduation" size="md" :on-hide-only-x="true" @bHideModalHeaderClose="closeModal">-->
<!--                <template v-slot:CommonModalTitle>-->
<!--                    {{ $t('graduation_information') }}-->
<!--                </template>-->
<!--                <template v-slot:CommonModalContent v-if="process=='graduation'">-->
<!--                    <ValidationObserver ref="formGraduationValidate">-->
<!--                        <ValidationProvider name="graduation_decision_date" rules="required" v-slot="{errors}">-->
<!--                            <b-form-group :label="$t('graduation_decision_date')">-->
<!--                                <select-date-->
<!--                                    v-model="formData.data.graduation_decision_date"-->
<!--                                    :validation-error="errors[0]"-->
<!--                                />-->
<!--                            </b-form-group>-->
<!--                        </ValidationProvider>-->
<!--                        <ValidationProvider name="graduation_semester_id" rules="required" v-slot="{errors}">-->
<!--                            <b-form-group :label="$t('graduation_semester')">-->
<!--                                <semesters-selectbox-->
<!--                                    v-model="formData.data.graduation_semester_id"-->
<!--                                    :validate-error="errors[0]"-->
<!--                                />-->
<!--                            </b-form-group>-->
<!--                        </ValidationProvider>-->
<!--                        <processing-button @click="save" :processing="loading" :label="'save'"/>-->
<!--                    </ValidationObserver>-->
<!--                </template>-->
<!--            </CommonModal>-->
        </app-layout>
    </div>
</template>

<script>
import AppLayout from "@/layouts/AppLayout.vue"
import Header from "@/layouts/AppLayout/Header.vue"
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile.vue"
import FacultySelectbox from "@/components/interactive-fields/FacultySelectbox.vue"
import DepartmentSelectbox from "@/components/interactive-fields/DepartmentSelectbox.vue"
import ProgramSelectbox from "@/components/interactive-fields/ProgramSelectbox.vue"
import CommonModal from "@/components/elements/CommonModal.vue"
import SelectDate from "@/components/interactive-fields/SelectDate.vue"
import LinedTextarea from "@/components/elements/LinedTextarea.vue"
import ProcessingButton from "@/components/elements/ProcessingButton.vue"
import StudentProgramService from "@/services/StudentProgramService"
import {ValidationObserver, ValidationProvider} from "vee-validate"
import qs from "qs"

export default {
    components: {
        ProcessingButton,
        HeaderMobile,
        Header,
        AppLayout,
        SelectDate,
        ProgramSelectbox,
        DepartmentSelectbox,
        FacultySelectbox,
        LinedTextarea,
        CommonModal,
        ValidationObserver,
        ValidationProvider
    },
    metaInfo() {
        return {
            title: this.$t('graduation_simulation')
        }
    },
    data() {
        return {
            formData: {
                filter: {
                    student_number: '',
                    faculty_code: null,
                    department_code: null,
                    program_code: null,
                },
                // data: {
                //     graduation_decision_date: null,
                //     graduation_semester_id: null
                // },
            },
            process: null,
            loading: false,
        }
    },
    methods: {
        // async showModal() {
        //     const isValidate = await this.$refs.formModalValidate.validate()
        //
        //     if (isValidate) {
        //         this.process = 'graduation'
        //         this.$refs.graduation.$refs.commonModal.show()
        //     }
        // },
        // closeModal() {
        //     this.process = null
        //     this.$refs.graduation.$refs.commonModal.hide()
        // },
        async save() {
            const isValidate = await this.$refs.formModalValidate.validate()

            if (isValidate) {
                this.loading = true
                const data = JSON.parse(JSON.stringify(this.formData))
                data.filter.student_number = data.filter.student_number.split('\n').filter(num => num)

                StudentProgramService.getSimulation(data)
                    .then((response) => {
                        this.$toast.success(this.$t('proccess_success'))
                        // this.closeModal()
                        this.$router.push('/job/logs/detail/'+response.data.data.log_id)
                    })
                    .catch(err => {
                        this.showErrors(err, this.$refs.graduation)
                        // this.closeModal()
                    })
            }
        }
    }
}
</script>
